import {ChangeEvent, useCallback, useState} from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ISponsorByToken} from '../../dto/Sponsor.dto';
import DialogNotes from '../../components/dialogs/DialogNotes';
import {apiPost} from '../../axiosConfig';
import DialogApiRequestStatus from '../../components/dialogs/DialogApiRequestStatus';
import MusicPreviewBtn from '../../components/music/MusicPreviewBtn';
import {SponsorRequestDetailsCard} from './SponsorshipRequest';

type Props = {
  data: ISponsorByToken;
  token: string;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const SponsorVerifyVoice = ({data, token}: Props) => {
  const [open, setOpen] = useState(false);
  const [message, SetMessage] = useState('');
  const [apiState, setApiState] = useState({status: '', error: null});
  const [type, setType] = useState('');
  const [close, setClose] = useState(false);
  const [file, setFile] = useState<File | null>();
  const [filename, setFilename] = useState('');

  const handleApproveStatus = useCallback(
    (type: string) => {
      setType(type);
      const payload = {
        type,
        message,
      };
      apiPost(`/v1/sponsor/${token}/verify-voice`, payload)
        .then(() => {
          setApiState({
            status: 'success',
            error: null,
          });
        })
        .catch(error => {
          setApiState({
            status: 'error',
            error,
          });
        });
    },
    [message, token],
  );
  const handleMessage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    SetMessage(event.target.value);
  }, []);
  const handleClose = () => {
    setOpen(false);
    SetMessage('');
    setApiState({
      status: '',
      error: null,
    });
    setClose(true);
  };
  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);
  const handleRemoveFile = () => {
    setFile(null);
  };
  const handleResubmit = useCallback(() => {
    setApiState({
      status: 'pending',
      error: null,
    });
    const payload = new FormData();
    payload.append(`file`, file as Blob);
    apiPost(`/v1/sponsor/${token}/reupload-voice`, payload)
      .then(() => {
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  }, [file, token]);

  const handleSubmit = useCallback(() => {
    if (
      data.type === 'verify-voiceover-file' ||
      data.type === 'campaign-file-review'
    ) {
      handleApproveStatus(type);
    }
    if (data.type === 'reupload-file') {
      handleResubmit();
    }
  }, [data.type, handleApproveStatus, handleResubmit, type]);

  if (close) {
    return (
      <Alert severity='error'>
        <AlertTitle>Request has been updated sucessfully!!</AlertTitle>
      </Alert>
    );
  }
  if (!close) {
    return (
      <Stack
        className='flex items-center justify-center'
        sx={{height: '100vh'}}>
        <Box className='sponsor-info-sec'>
          <Typography variant='h4' className='text-center font-bold mb-5'>
            Audio File Approval
          </Typography>
          {data.type !== 'campaign-file-review' ? (
            <Box>
              {token ? <SponsorRequestDetailsCard token={token} /> : null}
              <Stack direction={'row'} className='mb-2'>
                <Typography className='w-248 text-bold'>
                  Submitted At
                </Typography>
                <Typography>
                  : {dayjs(data?.created_at).format('MM/DD/YYYY hh:mm:ss A')}
                </Typography>
              </Stack>
              <Stack direction={'row'} className='mb-2'>
                <Typography className='w-248 text-bold'>
                  Last Updated
                </Typography>
                <Typography>
                  : {dayjs(data?.updated_at).format('MM/DD/YYYY hh:mm:ss A')}
                </Typography>
              </Stack>
            </Box>
          ) : null}

          {data.type === 'reupload-file' && (
            <>
              <Typography variant='body1' className='text-white-50'>
                Task Description
              </Typography>
              <Typography variant='subtitle1'>
                {data.sponsor_message.rejected_note}
              </Typography>
            </>
          )}
          {data.type !== 'verify-voiceover-file' &&
            data.type !== 'campaign-file-review' && (
              <Alert severity='info'>
                Upload file should match the previous file name -
                {data.sponsor_message.prepopulated_file_name}
              </Alert>
            )}
          {data.type === 'campaign-file-review' ? (
            <Stack direction='row' spacing={4} mr={2} mt={2}>
              <Typography variant='body1' className='text-white-50 pr-7'>
                {data.campaign_type.message.prepopulated_file_name}
              </Typography>
              <MusicPreviewBtn
                id={data.id}
                url={data.campaign_type.message.initial_url}
                track_name={
                  data.campaign_type.message.media_track?.track_name ??
                  data.campaign_type.message.prepopulated_file_name
                }
                track_artist={
                  data.campaign_type.message.media_track?.track_artist ??
                  data.campaign_type.message.prepopulated_file_name
                }
              />
              <IconButton
                component='a'
                href={data.campaign_type.message.initial_url}
                target='_blank'
                download={
                  data.campaign_type.message.prepopulated_file_name + '.mp3'
                }
                rel='noreferrer'>
                <DownloadIcon />
              </IconButton>
            </Stack>
          ) : (
            <Stack direction='row' spacing={4} mr={2} mt={2}>
              <Typography variant='body1' className='text-white-50 pr-7'>
                {data.sponsor_message.prepopulated_file_name}
              </Typography>
              <MusicPreviewBtn
                id={data.id}
                url={data.sponsor_message.initial_url}
                track_name={
                  data.sponsor_message.media_track?.track_name ||
                  data.sponsor_message.prepopulated_file_name
                }
                track_artist={data.sponsor_message.media_track?.track_artist}
              />
              <IconButton
                component='a'
                href={data.sponsor_message.initial_url}
                target='_blank'
                download={data.sponsor_message.prepopulated_file_name + '.mp3'}
                rel='noreferrer'>
                <DownloadIcon />
              </IconButton>
              {data.type !== 'verify-voiceover-file' && (
                <Button
                  component='label'
                  variant='outlined'
                  startIcon={<CloudUploadIcon />}>
                  Reupload File
                  <VisuallyHiddenInput
                    type='file'
                    accept='audio/*'
                    onChange={event => {
                      const files = event.target.files;
                      if (files && files.length > 0) {
                        const file = files[0];
                        setFilename(String(file?.name));
                        setFile(file);
                      }
                    }}
                  />
                </Button>
              )}
            </Stack>
          )}

          {file && (
            <List sx={{mt: 2}}>
              <ListItem sx={{p: 2, background: '#0d1525'}}>
                <ListItemText primary={file.name} />
                <ListItemIcon onClick={handleRemoveFile}>
                  <DeleteOutlineIcon />
                </ListItemIcon>
              </ListItem>
            </List>
          )}
          <Stack
            direction='row'
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 10,
            }}>
            {data.type !== 'reupload-file' && (
              <>
                <Button
                  variant='outlined'
                  className='min-w-150'
                  onClick={() => handleApproveStatus('approved')}>
                  Approve File
                </Button>
                <Button variant='outlined' onClick={() => setOpen(true)}>
                  Reassign to Re-record
                </Button>
              </>
            )}
            {data.type === 'reupload-file' && (
              <Button
                variant='outlined'
                sx={{
                  width: '20%',
                  p: 1,
                  mt: 2,
                }}
                onClick={handleResubmit}
                disabled={
                  !file ||
                  filename !==
                    data.sponsor_message.prepopulated_file_name + '.mp3'
                }>
                Reupload File
              </Button>
            )}
          </Stack>
          {apiState.status !== '' && (
            <DialogApiRequestStatus
              apiState={apiState}
              onRetry={handleSubmit}
              onEdit={handleEdit}
              onClose={handleClose}
            />
          )}
          <DialogNotes
            open={open}
            note={message}
            handleChange={handleMessage}
            handleSubmit={handleApproveStatus}
            handleClose={handleClose}
          />
        </Box>
      </Stack>
    );
  }
  return null;
};
export default SponsorVerifyVoice;
