import dayjs, {Dayjs} from 'dayjs';
import {Stack} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {useStoreSelector} from '../../../store/configstore';
import {useRqOndemandMsgRequestView} from '../../../react_query/on_demand_messages/OndemandMsgView';
import {IScripts} from '../event-specific/EsmMessages';
import {OndemandReview} from './OndemandReview';
import {UserCanAny} from '../../../components/UserCan';
import OnDemandActiveDialog from './OnDemandActiveDialog';
import RestoreButton from '../../../components/Button/RestoreButton';
import DeactivateButton from '../../../components/Button/DeactivateButton';
import {IOnDemandStation} from './OndemandStepper';

const ViewOnDemandMgsList = ({id}: {id: number}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const [stations, setStations] = useState<IOnDemandStation[]>([]);
  const [isExpireDateSet, setIsExpireDateSet] = useState<boolean>(false);
  const [expireDate, setExpireDate] = useState<Dayjs | null>(null);
  const [messages, setMessages] = useState<IScripts[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const {data, isFetched, isError} = useRqOndemandMsgRequestView(
    companyId,
    id,
    Boolean(id),
  );

  useEffect(() => {
    if (data) {
      if (data.stations) {
        setStations(
          data.stations?.map(station => ({
            ...station,
            custom_libraries: data.custom_library_request.filter(
              o => o.station_id === station.id,
            ),
          })),
        );
      }
      setIsExpireDateSet(data.is_expire_date_set === 1);
      if (data.expire_date) {
        setExpireDate(dayjs(data.expire_date));
      }
      if (Array.isArray(data.messages) && data.messages.length > 0) {
        const firstMessage = data.messages[0];
        const mDate: IScripts = {
          id: 1,
          uuid: '1',
          name: data.name,
          message: firstMessage?.message || '',
          requestable_type: 'ondemand-message',
          media_track: firstMessage?.media_track || undefined,
        };
        setMessages([mDate]); // Convert to an array
      } else {
        const mDate: IScripts = {
          id: 1,
          uuid: '1',
          name: data.name,
          message: '',
          requestable_type: 'ondemand-message',
        };
        setMessages([mDate]); // Convert to an array
      }
    }
  }, [data]);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return data && isFetched && !isError ? (
    <>
      <OndemandReview
        stations={stations}
        is_expire_date_set={isExpireDateSet}
        expire_date={expireDate}
        messages={messages}
        from='view'
      />
      <Stack direction='row' spacing={2} mt={2} justifyContent='center'>
        {data.status !== 'In Progress' ? (
          <UserCanAny scope='is-admin,partner,event-message:update-status'>
            {data.status === 'Inactive' ? (
              <RestoreButton onClick={openDialog} />
            ) : (
              <DeactivateButton onClick={openDialog} />
            )}
          </UserCanAny>
        ) : null}
      </Stack>
      {isDialogOpen ? (
        <OnDemandActiveDialog
          status={data.status}
          handleCancel={closeDialog}
          messageRequestId={data.id}
        />
      ) : null}
    </>
  ) : null;
};

export default ViewOnDemandMgsList;
