import {useCallback, useEffect, useRef, useState} from 'react';
import {Howl} from 'howler';
import {
  closePlayer,
  playPlayer,
  stopPlayer,
} from '../../../store/Slices/playerSlice';
import {useDispatch} from 'react-redux';

const useMiniPlayer = (songUrl: string | null, isMainPlaying: boolean) => {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentVolume, setCurrentVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const soundRef = useRef<Howl | null>(null);
  const intervalRef = useRef<number | null>(null);
  const toggleMute = useCallback(() => {
    setIsMuted(prev => {
      const newMuteState = !prev;
      soundRef.current?.mute(newMuteState);
      return newMuteState;
    });
  }, []);

  const handleVolumeChange = useCallback((_, value: number | number[]) => {
    const newVolume = value as number;
    if (!soundRef.current) return;
    setCurrentVolume(newVolume);
    soundRef.current?.volume(newVolume);
    setIsMuted(newVolume === 0);
  }, []);

  const togglePlay = useCallback(() => {
    if (soundRef.current) {
      if (isPlaying) {
        soundRef.current.pause();
        dispatch(stopPlayer());
      } else {
        soundRef.current.play();
        dispatch(playPlayer());
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying, dispatch]);

  const seekTo = useCallback((time: number) => {
    if (!soundRef.current) return;
    if (soundRef.current) {
      soundRef.current.seek(time);
      setCurrentTime(time);
    }
  }, []);

  const handleClose = () => {
    soundRef.current?.stop();
    soundRef.current?.unload();
    dispatch(closePlayer());
  };

  useEffect(() => {
    if (songUrl && isMainPlaying) {
      soundRef.current = new Howl({
        src: [songUrl],
        html5: true,
        preload: true,
        autoplay: true,
        onplay: () => {
          setIsPlaying(true);
          setDuration(soundRef.current?.duration() || 0);
          intervalRef.current = window.setInterval(() => {
            setCurrentTime(soundRef.current?.seek() || 0);
          }, 1000);
        },
        onend: () => {
          setIsPlaying(false);
          dispatch(stopPlayer());
        },
        onloaderror: (_, error) => console.error('Error loading track', error),
      });
    }

    return () => {
      if (soundRef.current) {
        soundRef.current.unload();
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [songUrl, dispatch, isMainPlaying]);

  return {
    isPlaying,
    currentTime,
    duration,
    currentVolume,
    isMuted,
    toggleMute,
    handleVolumeChange,
    togglePlay,
    seekTo,
    handleClose,
  };
};

export default useMiniPlayer;
