import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface PlayerState {
  isVisible: boolean;
  songUrl: string | null;
  id: number | null;
  track_name: string;
  track_artist: string;
  isPlaying: boolean;
}

const initialState: PlayerState = {
  isVisible: false,
  songUrl: null,
  track_name: '',
  track_artist: '',
  id: 0,
  isPlaying: false,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    playSong: (
      state,
      action: PayloadAction<{
        songUrl: string;
        id: number;
        track_name: string;
        track_artist: string;
      }>,
    ) => {
      state.songUrl = action.payload.songUrl;
      state.id = action.payload.id;
      state.track_name = action.payload.track_name;
      state.track_artist = action.payload.track_artist;
      state.isVisible = true;
      state.isPlaying = true;
    },
    playPlayer: state => {
      state.isPlaying = true; // Toggle playback state
    },
    stopPlayer: state => {
      state.isPlaying = false;
    },
    closePlayer: state => {
      state.isVisible = false;
      state.isPlaying = false;
    },
  },
});

export const {playSong, closePlayer, playPlayer, stopPlayer} =
  playerSlice.actions;
export default playerSlice.reducer;
