import {useCallback, useState} from 'react';
import {Button} from '@mui/material';
import ChooseExistingCampaignDialog from './ChooseExistingCampaignDialog';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import IconChooseMessage from '../../asset/icons/icon_timedmessage.svg';
import ProcessedFileUploadButton from '../messages/timed-message/request/contents/UploadProcessedFileDialog';

type Props = {
  onChange: (tracks: IMediaTrack[]) => void;
  tracks: IMediaTrack[];
  label: string;
  fileUploadLabel?: string;
  type: string;
  max: number;
  companyId?: number;
  disabled?: boolean;
  disableUpload?: boolean;
};

const ExistingCampaignChooserBtn = ({
  tracks,
  onChange,
  label,
  fileUploadLabel = 'Upload A File',
  type,
  max,
  companyId,
  disabled = false,
  disableUpload = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(
    (tracks: IMediaTrack[]) => {
      onChange(tracks);
      setOpen(false);
    },
    [onChange],
  );
  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        disabled={disabled}
        startIcon={<img src={IconChooseMessage} alt='choose icon' />}
        onClick={() => setOpen(true)}>
        {label}
      </Button>
      {!disableUpload && (
        <ProcessedFileUploadButton
          type={type}
          companyId={companyId}
          onClose={(track?: IMediaTrack) => {
            if (track) {
              onChange(max === 1 ? [track] : [...tracks, track]);
            }
          }}
          label={fileUploadLabel}
          isDisabled={disabled}
        />
      )}
      {open && (
        <ChooseExistingCampaignDialog
          defaultTracks={tracks}
          onClose={handleClose}
          type={type}
          max={max}
          companyId={companyId}
        />
      )}
    </>
  );
};
export default ExistingCampaignChooserBtn;
