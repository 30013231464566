import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import {DialogClose} from './dialogs/DialogFrame/DialogClose';

type Props = {
  open: boolean;
  status: boolean | number;
  handleSubmit: () => void;
  handleCancel: () => void;
};
const CommonDialog = ({open, status, handleSubmit, handleCancel}: Props) => {
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullWidth
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>
        <Typography className='alignCenter'>Change Request Status</Typography>
        <DialogClose onClose={handleCancel} />
      </DialogTitle>

      <DialogContent>
        <h5 className='text-white text-center'>
          Are you sure you want to {!status ? 'restore' : 'deactivate'} this
          request ?
        </h5>
      </DialogContent>
      <DialogActions className='flex items-center justify-center'>
        <Button variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant='outlined' color='info' onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommonDialog;

export const CommonDialogFrame = ({
  title,
  onClose,
  children,
  dialogProps = {open: true, fullWidth: true, maxWidth: 'sm'},
}: {
  title: string;
  onClose: () => void;
  children: JSX.Element;
  dialogProps: DialogProps;
}) => {
  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>
        <Typography className='alignCenter'>{title}</Typography>
        <DialogClose onClose={onClose} />
      </DialogTitle>
      {children}
    </Dialog>
  );
};
