import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {apiGet} from '../../axiosConfig';
import {
  ApiResponse,
  IApiDataResponse,
  IApiPaginationResponse2,
} from '../../dto/API.dto';
import {
  ICommercialListProp,
  ICommercialViewProp,
  ISponsorFull,
  ISponsorListProp,
  ISponsorListRes,
} from '../../dto/Sponsor.dto';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import {GridSortModel} from '@mui/x-data-grid';

export const queryKeyAiVoicePersons = (companyId: number) =>
  `v1/company/${companyId}/ai-voice-artists`;

export const queryKeySponsorList = (companyId: number) => {
  return `v1/company/${companyId}/sponsor`;
};
const getSponsorsList = async (companyId: number, tab: string) => {
  return await apiGet(queryKeySponsorList(companyId), {
    params: {status: tab},
  }).then((response: AxiosResponse<ISponsorListRes>) => {
    return response.data;
  });
};
export const useRqSponsorList = (companyId: number, tab: string) => {
  return useQuery({
    queryKey: [queryKeySponsorList(companyId), tab],
    queryFn: () => getSponsorsList(companyId, tab),
    refetchOnWindowFocus: false,
    enabled: Boolean(companyId),
  });
};

export type IDataGridProTritonSponsor = {
  search?: string;
  page: number;
  pageSize: number;
  sort?: GridSortModel;
  type: string;
  company_id?: number;
};
export type ITritonCampaignTrack = IMediaTrack & {
  advertiser: string;
  campaign_id: number;
  campaign_name: string;
  campaign_tag: string;
  track_id: number;
};

export type IAiVoicePersonResponse = {
  id: number;
  name: string;
};

const apiGetTritonSponsors = (query: IDataGridProTritonSponsor) => {
  return apiGet(`/v1/triton/campaigns`, {
    params: {...query, page: query.page + 1},
  }).then(
    (
      response: AxiosResponse<IApiPaginationResponse2<ITritonCampaignTrack>>,
    ) => {
      return response.data;
    },
  );
};
export const useRqTritonSponsorList = (query: IDataGridProTritonSponsor) => {
  return useQuery({
    queryKey: ['triton/campaigns', query],
    queryFn: () => apiGetTritonSponsors(query),
  });
};

export const apiPathGetSponsorById = (company_id: number, id: number) => {
  return `/v1/company/${company_id}/sponsor/${id}`;
};
const apiGetSponsorById = (company_id: number, id: number) => {
  return apiGet(apiPathGetSponsorById(company_id, id)).then(
    (response: AxiosResponse<ApiResponse<ISponsorFull>>) => {
      return response.data.data;
    },
  );
};
export const useRqGetSponsorById = (company_id: number, id: number) => {
  return useQuery({
    queryKey: [apiPathGetSponsorById(company_id, id)],
    queryFn: () => apiGetSponsorById(company_id, id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id > 0,
  });
};
export const queryKeySponsorCommercialType = (search: string) => {
  return 'v1/commercial' + (search ? '?search=' + search : '');
};
const getCommercialTypeList = async (search: string) => {
  return await apiGet(queryKeySponsorCommercialType(search)).then(
    (response: AxiosResponse<IApiDataResponse<ICommercialListProp[]>>) => {
      return response.data;
    },
  );
};
export const useRqCommercialTypeList = (search: string) => {
  return useQuery({
    queryKey: [queryKeySponsorCommercialType(search)],
    queryFn: () => getCommercialTypeList(search),
    refetchOnWindowFocus: false,
  });
};

export const queryKeySponsorCommercialTypeById = (id: number) => {
  return `v1/commercial/${id}`;
};
const getCommercialTypeListById = async (id: number) => {
  return await apiGet(queryKeySponsorCommercialTypeById(id)).then(
    (response: AxiosResponse<IApiDataResponse<ICommercialViewProp>>) => {
      return response.data;
    },
  );
};

export const apiPathGetSponsorReamainingCount = (company_id: number) => {
  return `/v1/company/${company_id}/sponsor/count`;
};
const apiGetSponsorReamainingCount = (company_id: number) => {
  return apiGet(apiPathGetSponsorReamainingCount(company_id)).then(
    (response: AxiosResponse<ApiResponse<number>>) => {
      return response.data;
    },
  );
};
export const useRqCommercialTypeListById = (id: number) => {
  return useQuery({
    queryKey: [queryKeySponsorCommercialTypeById(id)],
    queryFn: () => getCommercialTypeListById(id),
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });
};

export const queryKeySponsorCommercialList = () => {
  return `v1/commercial/sponsor`;
};
const getSponsorCommercialList = async () => {
  return await apiGet(queryKeySponsorCommercialList()).then(
    (response: AxiosResponse<IApiDataResponse<ISponsorListProp[]>>) => {
      return response.data.data;
    },
  );
};
export const useRqSponsorCommercialList = () => {
  return useQuery({
    queryKey: [queryKeySponsorCommercialList()],
    queryFn: () => getSponsorCommercialList(),
    refetchOnWindowFocus: false,
  });
};
export const useRqGetSponsorRemainingCount = (company_id: number) => {
  return useQuery({
    queryKey: [apiPathGetSponsorReamainingCount(company_id)],
    queryFn: () => apiGetSponsorReamainingCount(company_id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(company_id),
  });
};

const getAiVoicePersons = async (companyId: number) => {
  return await apiGet(queryKeyAiVoicePersons(companyId)).then(
    (response: AxiosResponse<IApiDataResponse<IAiVoicePersonResponse[]>>) => {
      return response.data.data;
    },
  );
};

export const useRqGetAiVoicePersons = (companyId: number) => {
  return useQuery({
    queryKey: [queryKeyAiVoicePersons(companyId)],
    queryFn: () => getAiVoicePersons(companyId),
    refetchOnWindowFocus: false,
    retry: false,
  });
};
