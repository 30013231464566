import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  DialogActions,
  Typography,
} from '@mui/material';
import {Add, Done} from '@mui/icons-material';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import {DataGridCustPagination} from '../../components/utlis/DataGrid/Pagination';
import {
  IDataGridProTritonSponsor,
  ITritonCampaignTrack,
  useRqTritonSponsorList,
} from '../../react_query/sponsor_commercial/SponsorCommercialQuery';
import {defaultDataGridProSearchSort} from '../../dto/Html.dto';
import MusicPreviewBtn from '../../components/music/MusicPreviewBtn';
import InputSearch from '../../components/styles/InputSearch';
import {DialogClose} from '../../components/dialogs/DialogFrame/DialogClose';

type Props = {
  defaultTracks: IMediaTrack[];
  onClose: (tracks: IMediaTrack[]) => void;
  type: string;
  max: number;
  companyId?: number;
};

const ChooseExistingCampaignDialog = ({
  defaultTracks,
  onClose,
  type,
  max,
  companyId,
}: Props) => {
  const [selectedTracks, setSelectedTracks] = useState(defaultTracks);
  const [selectedTrackIds, setSelectedTrackIds] = useState<number[]>([]);
  const [query, setQuery] = useState<IDataGridProTritonSponsor>({
    ...defaultDataGridProSearchSort,
    type,
    company_id: companyId,
  });
  const {data, isLoading} = useRqTritonSponsorList(query);
  const handleSearch = useCallback((newValue: string) => {
    setQuery(prev => ({
      ...prev,
      search: newValue,
    }));
  }, []);
  const handlePagination = useCallback((paginate: GridPaginationModel) => {
    setQuery(prev => ({...prev, ...paginate}));
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    setQuery(prev => ({...prev, sort: model}));
  };
  const handleSongAdd = useCallback(
    (musicTrack: ITritonCampaignTrack) => {
      const track = {...musicTrack, id: musicTrack.track_id};
      setSelectedTracks(prev => {
        if (prev.find(t => t.id === track.id)) {
          return prev;
        }
        if (max === 1) return [track];
        return [...prev, track];
      });
    },
    [max],
  );

  const handleSongRemove = useCallback((musicTrack: ITritonCampaignTrack) => {
    setSelectedTracks(prev => prev.filter(t => t.id !== musicTrack.track_id));
  }, []);
  const handleClose = useCallback(() => {
    onClose(defaultTracks);
  }, [defaultTracks, onClose]);
  const handleProceed = useCallback(() => {
    onClose(selectedTracks);
  }, [selectedTracks, onClose]);
  useEffect(() => {
    setSelectedTrackIds(selectedTracks.map(o => o.id));
  }, [selectedTracks]);
  const columnVisibilityModel = useMemo(() => {
    if ('timed-message' === type) {
      return {
        campaign_name: false,
        campaign_tag: false,
        advertiser: false,
        campaign_starttime: false,
        campaign_endtime: false,
      } as Record<string, boolean>;
    } else if ('wristband-message' === type) {
      return {
        campaign_name: false,
        campaign_tag: false,
        advertiser: false,
        campaign_starttime: false,
        campaign_endtime: false,
        playlist_start_date: false,
        playlist_end_date: false,
        playlist_start_time: false,
        playlist_end_time: false,
      } as Record<string, boolean>;
    }
    return {
      play_time: false,
      playlist_name: false,
      playlist_repeat: false,
      playlist_start_date: false,
      playlist_end_date: false,
      playlist_start_time: false,
      playlist_end_time: false,
    } as Record<string, boolean>;
  }, [type]);
  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'url',
          type: 'actions',
          headerName: 'Play',
          width: 54,
          renderCell: (params: GridRenderCellParams<ITritonCampaignTrack>) =>
            params.row.url ? (
              <MusicPreviewBtn
                id={params.row.id}
                url={params.row.url}
                track_name={params.row.track_name || params.row.on_demand_label}
                track_artist={params.row.track_artist}
              />
            ) : null,
        },
        {
          field: 'advertiser',
          headerName: 'Advertiser',
          flex: 1,
        },
        {
          field: 'campaign_name',
          headerName: 'Campaign Name',
          flex: 1,
        },
        {
          field: 'campaign_tag',
          headerName: 'Campaign Tag',
          width: 150,
        },
        {
          field: 'track_name',
          headerName: 'Song Title',
          flex: 1,
          renderCell: (params: GridRenderCellParams<ITritonCampaignTrack>) =>
            params.row.on_demand_label || params.row.track_name,
        },
        {
          field: 'play_time',
          headerName: 'Scheduled Time',
          width: 95,
        },
        {
          field: 'playlist_name',
          headerName: 'Playlist Name',
          width: 105,
        },
        {
          field: 'playlist_repeat',
          headerName: 'Playlist Repeat',
          width: 105,
        },
        {
          field: 'playlist_start_date',
          headerName: 'Start Date',
          width: 105,
        },
        {
          field: 'playlist_end_date',
          headerName: 'End Date',
          width: 105,
        },
        {
          field: 'playlist_start_time',
          headerName: 'Start Time',
          width: 95,
        },
        {
          field: 'playlist_end_time',
          headerName: 'End Time',
          width: 95,
        },
        {
          field: 'campaign_starttime',
          headerName: 'Start Date',
          width: 105,
          renderCell: (params: GridRenderCellParams) => {
            return params.value ? dayjs(params.value).format('MM/DD/YYYY') : '';
          },
        },
        {
          field: 'campaign_endtime',
          headerName: 'End Date',
          width: 105,
          renderCell: (params: GridRenderCellParams) => {
            return params.value ? dayjs(params.value).format('MM/DD/YYYY') : '';
          },
        },
        {
          field: 'station_names',
          headerName: 'Station Names',
          flex: 1,
        },
        {
          type: 'actions',
          field: 'actions',
          headerName: 'Action',
          hideable: false,
          width: 125,
          renderCell: (params: GridRenderCellParams<ITritonCampaignTrack>) =>
            selectedTrackIds.includes(params.row.track_id) ? (
              <IconButton
                aria-label='delete'
                onClick={() => {
                  handleSongRemove(params.row);
                }}
                color='success'>
                <Done />
              </IconButton>
            ) : (
              <IconButton
                aria-label='add'
                onClick={() => {
                  handleSongAdd(params.row);
                }}
                disabled={max > 1 && max === selectedTrackIds.length}
                color='secondary'>
                <Add />
              </IconButton>
            ),
        },
      ].map(column => ({
        ...column,
        filterable: false,
      })),
    [handleSongAdd, handleSongRemove, max, selectedTrackIds],
  );
  return (
    <Dialog open maxWidth='xl' fullWidth onClose={handleClose}>
      <DialogTitle>
        <Typography>Choose Campaign Track</Typography>
        <DialogClose onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <InputSearch
          className='max-w-347 mb-2 ml-auto'
          placeholder='Search'
          value={query.search || ''}
          onChange={handleSearch}
        />

        <DataGridPro
          rows={data?.data ?? []}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          loading={isLoading}
          rowCount={data?.total ?? 0}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={{page: query.page, pageSize: query.pageSize}}
          paginationMode='server'
          pagination={true}
          onPaginationModelChange={handlePagination}
          onSortModelChange={handleSortModelChange}
          density='compact'
          disableRowSelectionOnClick
          disableColumnMenu
          slots={{
            pagination: DataGridCustPagination,
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
          getRowId={row => row.unique_id}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleProceed}>
          Proceed
        </Button>
        <Button variant='outlined' onClick={handleProceed}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChooseExistingCampaignDialog;
