import dayjs from 'dayjs';
import {Button, Stack} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useStoreSelector} from '../../../store/configstore';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {useRqGetEventMessageByRequestId} from '../../../react_query/messages/messages-details/ViewEventMessages';
import EsmReview from './EsmReview';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {IStation} from '../../../dto/Station.dto';
import {IEventType} from '../../../dto/EventType.dto';
import {IScripts} from './EsmMessages';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import {UserCanAny} from '../../../components/UserCan';

export interface IDates {
  id: number;
  uuid: string;
  start_date: string | null;
  end_date: string | null;
}
export interface MessageCustomProps {
  uuid: number;
  name: string;
  message: string;
  media_track?: IMediaTrack;
}

// Union type combining both MessageCustomProps and MessagesProps
export type CombinedMessageProps = MessageCustomProps | IScripts;

const EventSpecificViewMessage = ({
  id,
  handleStatus,
}: {
  id: number;
  handleStatus: (id: number) => void;
}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const {data, isError} = useRqGetEventMessageByRequestId(
    companyId,
    id,
    Boolean(id),
  );
  const [schools, setSchools] = useState<ICompanySchool[]>([]);
  const [stations, setStations] = useState<IStation[]>([]);
  const [events, setEvents] = useState<IEventType[]>([]);
  const [messages, setMessages] = useState<CombinedMessageProps[]>([]);
  const multipleDates = useMemo(() => {
    if (data && !isError) {
      return data.multiple_dates.map(o => ({
        ...o,
        start_date: dayjs(o.start_date),
        end_date: dayjs(o.end_date),
        start_time: dayjs(o.start_time, 'HH:mm:ss'),
        end_time: dayjs(o.end_time, 'HH:mm:ss'),
      }));
    }
    return [];
  }, [data, isError]);

  useEffect(() => {
    if (data) {
      setSchools(data.schools);
      setStations(data.stations);
      setEvents(data?.event_types);

      // Check if data.messages is an array and set the first message if it exists
      if (Array.isArray(data.messages) && data.messages.length > 0) {
        const firstMessage = data.messages[0];
        const mDate: CombinedMessageProps = {
          uuid: 1,
          name: data.name,
          message: firstMessage?.message || '',
          media_track: firstMessage?.media_track,
        };
        setMessages([mDate]); // Convert to an array
      } else {
        const mDate: CombinedMessageProps = {
          uuid: 1,
          name: data.name,
          message: '',
          media_track: undefined,
        };
        setMessages([mDate]); // Convert to an array
      }
    }
  }, [data]);

  return (
    <>
      {data && !isError ? (
        <>
          <EsmReview
            schools={schools}
            stations={stations}
            events={events}
            multiple_dates={multipleDates}
            other_event_name=''
            messages={messages}
            created_date={data.created_at}
            created_by={data.created_by.name}
            from='view'
            days={data.week_days}
          />
          <Stack direction='row' spacing={2} mt={2} justifyContent='center'>
            {data.status !== 'In Progress' ? (
              <UserCanAny scope='is-admin,partner,event-message:update-status'>
                {data.status === 'Inactive' ? (
                  <Button
                    variant='outlined'
                    color='success'
                    size='small'
                    onClick={() => handleStatus(data.id)}>
                    Restore
                  </Button>
                ) : (
                  <Button
                    variant='outlined'
                    color='error'
                    size='small'
                    onClick={() => handleStatus(data.id)}>
                    Deactivate
                  </Button>
                )}
              </UserCanAny>
            ) : null}
          </Stack>
        </>
      ) : null}
    </>
  );
};
export default EventSpecificViewMessage;
